import React, { useState, useEffect } from 'react';
import './App.css';
import * as PIXI from 'pixi.js';
import { Ticker } from 'pixi.js';
import { Live2DModel } from 'pixi-live2d-display';
import html2canvas from 'html2canvas';

Live2DModel.registerTicker(Ticker);

export default function App() {
  const [userMessage, setUserMessage] = useState('');
  const [model, setModel] = useState(null);  // modelをstateで管理
  const [messageLog, setMessageLog] = useState([]);  // メッセージの履歴を管理するstate
  const [app, setApp] = useState(null);  // appをstateで管理

  useEffect(() => {
    const app = new PIXI.Application({
      view: document.getElementById('live2d-canvas'),
      backgroundColor: 0x252743
    });
    setApp(app);  // appをstateにセット

    (async () => {
      const loadedModel = await Live2DModel.from('./avatar/mao/mao_pro_t02.model3.json');
      setModel(loadedModel);  // modelをstateにセット
      app.stage.addChild(loadedModel);

      // 位置やスケールの調整
      loadedModel.x = -180;
      loadedModel.y = -100;
      loadedModel.scale.set(0.2, 0.2);

      // インタラクション
      loadedModel.on('hit', (hitAreas) => {
        // console.log(hitAreas);
        if (hitAreas.includes('body')) {
          loadedModel.expression(7);  // setExpressionを使用
          setTimeout(() => {
            loadedModel.expression(0);  // setExpressionを使用
          }, 2000)
        } else {
          loadedModel.expression(6);  // setExpressionを使用
          setTimeout(() => {
            loadedModel.expression(0);  // setExpressionを使用
          }, 2000)
        }
      });
    })();
  }, []);

  const generateCombinedImage = async () => {
    if (!app || !model) return null;
  
    const canvas = app.view;
    const { width, height } = canvas;
    const centerX = width / 2;
    const centerY = height / 2;
  
    model.focus(centerX, centerY);
    await new Promise(resolve => setTimeout(resolve, 500));
    app.renderer.render(app.stage);
    const pixiDataURL = app.view.toDataURL("image/png");
  
    const chatLogDiv = document.querySelector('.list');
    const chatLogCanvas = await html2canvas(chatLogDiv);
    const chatLogDataURL = chatLogCanvas.toDataURL("image/png");
  
    const combinedCanvas = document.createElement('canvas');
    combinedCanvas.width = app.view.width;
    combinedCanvas.height = app.view.height + chatLogCanvas.height;
    const ctx = combinedCanvas.getContext('2d');
  
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, combinedCanvas.width, combinedCanvas.height);
  
    const pixiImage = new Image();
    pixiImage.src = pixiDataURL;
    await new Promise(r => pixiImage.onload = r);
  
    const chatLogImage = new Image();
    chatLogImage.src = chatLogDataURL;
    await new Promise(r => chatLogImage.onload = r);

    ctx.drawImage(pixiImage, 0, 0);

    const targetWidth = combinedCanvas.width * 0.9; // combinedCanvasの横幅の90%
    const scale = targetWidth / chatLogImage.width; // スケールを計算
    const targetHeight = chatLogImage.height * scale; // 縦幅もスケールに合わせる
    
    // 画像を中央に配置するためのX座標を計算
    const offsetX = (combinedCanvas.width - targetWidth) / 2;
    
    ctx.drawImage(chatLogImage, offsetX, app.view.height, targetWidth, targetHeight);
  
    return combinedCanvas;
  };
  
  const captureAndSave = async () => {
    const combinedCanvas = await generateCombinedImage();
    if (!combinedCanvas) return;
  
    const combinedDataURL = combinedCanvas.toDataURL("image/png");
    const a = document.createElement('a');
    a.href = combinedDataURL;
    a.download = 'combined_screenshot.png';
    a.click();
  };
  
  const shareCombinedImage = async () => {
    const combinedCanvas = await generateCombinedImage();
    if (!combinedCanvas) return;
  
    combinedCanvas.toBlob(async (blob) => {
      const file = new File([blob], 'combined_screenshot.png', { type: 'image/png' });
      if (navigator.share && navigator.canShare({ files: [file] })) {
        await navigator.share({
          files: [file],
          title: 'テストタイトル',
          text: 'テストテキスト',
        });
      }
    }, 'image/png');
  };

  const analyzeEmotion = (text) => {
    if (text.includes('【喜】') || text.includes('【喜】')) {
      return '喜';
    } else if (text.includes('【怒】') || text.includes('（怒）')) {
      return '怒';
    } else if (text.includes('【哀】') || text.includes('（哀）')) {
      return '哀';
    } else if (text.endsWith('【楽】') || text.includes('（楽）')) {
      return '楽';
    } else if (text.endsWith('【羞恥】') || text.includes('（羞恥）')) {
      return '羞恥';
    } else {
      return '中立';
    }
  };

  function removeEmotionBrackets(str) {
    const emotions = ['喜', '怒', '哀', '楽', '羞恥'];  // ここに感情のリストを追加
    let cleanedText = str;
  
    emotions.forEach((emotion) => {
      const regexFullWidth = new RegExp(`【${emotion}】`, 'g');
      const regexHalfWidth = new RegExp(`\\[${emotion}\\]`, 'g');
      const regexFullWidthParen = new RegExp(`（${emotion}）`, 'g');
      const regexHalfWidthParen = new RegExp(`\\(${emotion}\\)`, 'g');
  
      cleanedText = cleanedText
        .replace(regexFullWidth, '')
        .replace(regexHalfWidth, '')
        .replace(regexFullWidthParen, '')
        .replace(regexHalfWidthParen, '');
    });
  
    return cleanedText;
  }

  const setModelExpression = (emotion) => {
    if (model) {
      switch (emotion) {
        case '喜':
          Math.random() < 0.5 ? model.expression(1) : model.expression(2);
          break;
        case '努':
          model.expression(7);
          break;
        case '哀':
          model.expression(4);
          break;
        case '楽':
          Math.random() < 0.5 ? model.expression(3) : model.expression(1);
          break;
        case '羞恥':
          model.expression(5);
          break;
        default:
          model.expression(0);
          break;
      }
      const canvas = app.view;
      const { width, height } = canvas;
      const centerX = width / 2;
      const centerY = height / 2;
    
      model.focus(centerX, centerY);
      // setTimeout(() => {
      //   model.expression(0);
      // }, 5000)
    }

  };

  const handleSendMessage = async () => {
    const payload = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: "You are a helpful assistant."
        },
        {
          role: "user",
          content: userMessage
        }
      ]
    };



    const response = await fetch('https://production.worker-small-glitter-1e1a.reyq-0214vd.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(payload)
    });

    const data = await response.json();
    const gptResponse = data.choices[0]?.message?.content || 'No response';

    // メッセージの履歴に追加
    setMessageLog(prevLog => [
      ...prevLog,
      { role: 'user', content: userMessage },
      { role: 'bot', content: removeEmotionBrackets(gptResponse) }
    ]);

    const emotion = analyzeEmotion(gptResponse);
    setModelExpression(emotion);

  };

  return (
    <div className="wrapper">
      <canvas id="live2d-canvas"></canvas>
      <div className="uipanel">
        <div className="input">
          <input
            type="text"
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
          />
          <button onClick={handleSendMessage}>送信</button>
        </div>
        <div className="downloads">
          <button onClick={captureAndSave}>画像として保存</button>
          <button onClick={shareCombinedImage}>画像をシェア</button>
        </div>
        <div className="list">
          {messageLog.slice().reverse().map((message, index) => (
            <div key={index} className={message.role}>
              {message.content}
            </div>
          ))}
        </div>

      </div>
      <ul className='readme'>
        <li>検証のためにGPT-3.5-turboとGPT-4とで切り替わる事があります。</li>
        <li>シェア機能はWindows/Android版のChromeとiOS版のSafariで機能します。(Web Share API Level 2に対応している環境)</li>
        <li>検証サイトなので予告無く閉じる事があります。</li>
      </ul>
      <p className="copy">本作品のキャラクターには株式会社Live2Dの著作物であるサンプルデータが株式会社Live2Dの定める規約に従って用いられています。本作品は制作者の完全な自己の裁量で制作されています。</p>
    </div>
  );
}